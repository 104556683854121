@import "collapsibleItem";
@import "toastMessage";
@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

$footer-grey: #a8a8a8;

footer {
    background-color: black;
    font-size: 13px;
    padding: 50px 24px;

    /**
     * Safari bugfix:
     * Resolves an issue where unexpected white space appears at the bottom of the page.
     */
    position: relative;
    overflow: hidden;

    @include media-breakpoint-up(sm) {
        padding: 90px 24px 55px 24px;
    }

    &.footer-light {
        padding: 40px;
        color: white;
    }

    nav {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;

        .copy-right-container {
            display: flex;
            justify-content: center;
            text-align: center;
            width: 100%;

            .copy-right {
                display: flex;
                width: 100%;
                padding-top: 16px;
                border-top: 1px solid $footer-grey;
                color: $footer-grey;
                justify-content: center;
                max-width: 1092px + 48px;

                ul {
                    display: flex;
                    column-gap: 16px;
                    flex-wrap: wrap;
                    flex-direction: column;
                    align-items: center;

                    @include media-breakpoint-up(sm) {
                        flex-direction: row;
                        align-items: flex-start;
                    }
                }

                li {
                    padding-bottom: 24px;

                    &:last-child {
                        border-right: none;
                        padding-right: 0;
                    }

                    @include media-breakpoint-up(sm) {
                        padding-right: 16px;
                        padding-bottom: 0;
                        border-right: 1px solid $footer-grey;
                    }

                    + li {
                        @include media-breakpoint-down(xs) {
                            padding-bottom: 8px;
                        }
                    }
                }
            }
        }

        .main-content {
            columns: 1;
            column-gap: 24px;
            padding-bottom: 10px;
            width: 100%;
            max-width: 1092px;
            text-align: center;
            align-items: center;

            @include media-breakpoint-up(sm) {
                columns: 2;
                text-align: left;
                align-items: flex-start;
                padding-bottom: 40px;
            }

            @include media-breakpoint-up(md) {
                columns: 3;
            }

            @include media-breakpoint-up(lg) {
                columns: 4;
            }

            .link-group {
                break-inside: avoid;
                margin-bottom: 40px;

                .link-group-title {
                    font-size: 14px;
                    font-weight: 600;
                    color: #fff;
                    text-transform: uppercase;
                    margin-bottom: 8px;
                }

                ul {
                    display: flex;
                    flex-direction: column;
                    row-gap: 8px;
                }

                ul.social {
                    display: inline-flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                    column-gap: 20px;

                    @include media-breakpoint-up(sm) {
                        justify-content: flex-start;
                    }

                    .fa {
                        font-size: 24px;
                    }

                    .fa:hover {
                        color: white;
                    }
                }
            }
        }
    }

    ul {
        padding-bottom: 0;
        padding-left: 0;
        margin-bottom: 0;
    }

    li {
        list-style-type: none;
    }

    a {
        text-decoration: none;
        color: $footer-grey;
        transition: color 320ms;
    }

    a:hover {
        color: #fff;
        text-decoration: none;
    }
}

#consent-tracking {
    .button-wrapper {
        button {
            margin: 0.5em;
        }
    }
}

.email-signup-message {
    @include toast-message();
}

.email-signup-alert {
    @include toast-alert();
}

.input-newsletter {
    @include media-breakpoint-down(md) {
        margin-top: 1rem;
    }
}

// newsLetter
.home-email-signup {
    background-color: $blue-footer;
    overflow: hidden;
    color: #fff;

    // padding: 30px 15px;
    // @include media-breakpoint-up(md) {
    //     display: flex;
    //     justify-content: center;
    //     padding: 40px 15px;
    // }

    h2 {
        font-size: 16px;
        font-weight: inherit;
        margin: 0;
        text-align: center;
        // @include media-breakpoint-up(md) {
        //     display: flex;
        //     align-items: center;
        //     justify-content: flex-end;
        //     margin: 0 20px 0 0;
        //     flex-shrink: 0;
        //     font-size: 18px;
        //     text-align: left;
        // }
    }

    >.container {
        padding: 40px 0;
    }

    .email-description {
        display: flex;
        align-items: center;
        color: $white;
    }

    .subscribe-email {
        margin-left: 10px;
    }

    .newsletter-input {
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: $white;

        &::placeholder {
            color: rgba(255, 255, 255, 0.6);
        }
    }
}

.title-footer-sections {
    font-family: 'Inter';
    font-weight: 300;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $grey5;
    margin-bottom: 20px;
    width: 285px;

    @include media-breakpoint-up(md) {
        margin-left: auto;
    }

    @include media-breakpoint-down(lg) {
        margin-bottom: 8px;
    }

    @include media-breakpoint-down(md) {
        width: 250px;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
    }
}

.has-newsletter-footer {
    background-color: $blue-footer;
    transition: height 400ms;
}

.has-newsletter-form-footer {
    padding: 40px 15px;
    color: #fff;
    transition: opacity 200ms;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        font-size: 16px;
        line-height: 26px;
        font-weight: 600;
        margin-bottom: 30px;
        text-align: center;
        text-transform: uppercase;
        max-width: 1440px;

        @include media-breakpoint-up(md) {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 20px;
        }

        @include media-breakpoint-up(lg) {
            white-space: pre-line;
        }
    }

    form {
        max-width: 100%;

        .row-1 {
            display: flex;
            width: 525px;
            max-width: 100%;
        }

        .row-2 {
            margin-top: 12px;
            position: relative;
            max-width: 525px;
        }
    }

    input[type="email"] {
        width: 100%;
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid rgba(255, 255, 255, 0.2);
        padding: 0.5625rem 26px;
        color: #fff;
        border-radius: 2px;
        font-size: 16px;
        line-height: 1.5;

        &::placeholder {
            color: rgba(255, 255, 255, 0.6);
        }

        @include media-breakpoint-up(md) {
            font-size: 18px;
        }
    }

    input[type="email"]:focus {
        outline: none;
    }

    button {
        flex-shrink: 0;
        margin: 0 0 0 10px;
    }

    .material-checkbox {
        display: flex;

        label {
            display: inline-flex;
            align-items: center;
            font-size: 12px;
            margin: 0 0 0 -8px;
            cursor: pointer;
        }

        p {
            margin: 0;
        }

        .checkbox-wrapper {
            position: relative;
            display: inline-block;
            padding: 6px;

            input {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                opacity: 0;
            }

            input+span {
                display: block;
                height: 20px;
                width: 20px;
                background-image: url('../images/checkbox-unchecked-white.svg');
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                opacity: 0.54;
            }

            input:checked+span {
                background-image: url('../images/checkbox-checked-white.svg');
            }
        }

        a {
            color: #0078e0;
            font-family: "Inter", sans-serif;
            font-weight: 500;
        }
    }

    .checkbox-errors {
        position: absolute;

        .parsley-errors-list {
            color: #ff6b48;
            font-size: 10px;
            padding-left: 14px;
            margin-top: 3px;
        }
    }
}


.has-button-footer {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    display: inline-block;
    padding: 0;
    position: relative;
    background: transparent;
    border-color: transparent;
    border-width: 1px;
    border-style: solid;
    transition: color 0.2s, background-color 0.2s, border 0.2s;
    cursor: pointer;
    text-align: center;
    appearance: none;

    &:hover {
        text-decoration: none;
    }

    &.appearance--simple {
        font-size: 1rem;

        @include media-breakpoint-up(md) {
            font-size: 1.125rem;
        }

        &.color--primary {
            color: $blue-footer;
        }

        &.color--secondary {
            color: $hastens-bright-blue;
        }

        &.color--light {
            color: #fff;
        }

        &.color--dark {
            color: #000;
        }

        &.color--secondary-inverse {
            color: #fff;
        }
    }

    &.appearance--standard {
        border-radius: 5px;
        line-height: 1.5;

        &.color--primary {
            background: $blue-footer;
            border-color: $blue-footer;
            color: #fff;

            &:hover:not([disabled]) {
                background: #fff;
                border-color: #fff;
                color: $blue-footer;
            }
        }

        &.color--secondary {
            background: $hastens-bright-blue;
            border-color: $hastens-bright-blue;
            color: #fff;

            &:hover:not([disabled]) {
                background: #0054A8;
                border-color: #0054A8;
            }
        }

        &.color--secondary-inverse {
            background: #fff;
            border-color: #fff;
            color: $hastens-bright-blue;

            &:hover:not([disabled]) {
                background: $hastens-bright-blue;
                border-color: $hastens-bright-blue;
                color: #fff;
            }
        }

        &.color--light {
            background: #fff;
            border-color: #fff;
            color: #000;

            &:hover:not([disabled]) {
                background: #000;
                border-color: #000;
                color: #fff;
            }
        }

        &.color--dark {
            background: #000;
            border-color: #000;
            color: #fff;

            &:hover:not([disabled]) {
                background: #fff;
                border-color: #fff;
                color: #000;
            }
        }

        &.color--grey {
            background: #757575;
            border-color: #757575;
            color: #fff;

            &:hover:not([disabled]) {
                background: #fff;
                border-color: #fff;
                color: #000;
            }
        }

        &:disabled:not(.pending) {
            background: #757575;
            border-color: #757575;
            color: #fff;
            cursor: not-allowed;
            opacity: 0.65;
        }

        &.size--small {
            font-size: 0.875rem;
            padding: 0.3125rem 15px;
        }

        &.size--medium {
            font-size: 1rem;
            padding: 0.5625rem 25px;
        }

        &.size--large {
            font-size: 1rem;
            padding: 0.5625rem 25px;

            @include media-breakpoint-up(md) {
                font-size: 1.125rem;
                padding: 0.875rem 26px;
                line-height: 1.4;
            }
        }
    }

    &.appearance--outlined {
        border-radius: 2px;
        border-width: 2px;
        line-height: 1.5;

        &.color--light {
            background: transparent;
            border-color: #fff;
            color: #fff;

            &:hover:not([disabled]) {
                background: #fff;
                color: #000;
            }
        }

        &.color--dark {
            background: transparent;
            border-color: #000;
            color: #000;

            &:hover:not([disabled]) {
                background: #000;
                color: #fff;
            }
        }

        &:disabled:not(.pending) {
            border-color: #757575;
            color: #757575;
            cursor: not-allowed;
            opacity: 0.65;
        }

        &.size--small {
            font-size: 0.875rem;
            padding: 0.3125rem 15px;
        }

        &.size--medium {
            font-size: 1rem;
            padding: 0.5625rem 25px;
        }

        &.size--large {
            font-size: 1rem;
            padding: 0.5625rem 25px;

            @include media-breakpoint-up(md) {
                font-size: 1.125rem;
                padding: 0.875rem 26px;
                line-height: 1.4;
            }
        }
    }

    &.with-arrow .text {
        &::after {
            content: "\203A";
            font-size: 1em;
            margin-left: 0.4rem;
            position: relative;
            line-height: 1;
        }
    }

    &.should-animate-arrow .text {
        &::after {
            left: 0;
            transition: left 0.15s ease;
        }

        &:hover::after {
            left: 0.15em;
        }
    }

    &.pending {
        position: relative;

        .text {
            color: transparent;
        }

        .progress {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -10px;
            margin-left: -10px;
        }
    }
}

.has-newsletter-confirmation-footer {
    display: none;
    padding: 30px 15px;
    color: #fff;
    text-align: center;
    transition: opacity 200ms;

    @include media-breakpoint-up(md) {
        padding: 40px 15px;
    }

    .content-wrapper {
        max-width: 960px;
        margin: 0 auto;
    }

    h2 {
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 20px;
    }

    p {
        white-space: pre-line;
    }

    button,
    a {
        font-weight: 600;
        font-size: inherit;
        display: inline-block;
    }

    button {
        background-color: transparent;
        border: none;
        margin: 0;
        padding: 0;
        appearance: none;
    }
}

.has-paragraph {
    &.size--xs {
        font-size: 12px;
        line-height: 18px;
    }

    &.size--sm {
        font-size: 14px;
        line-height: 22px;
    }

    &.size--md {
        // If this is updated, please update TextColumns.scss as well
        font-size: 14px;
        line-height: 22px;

        @include media-breakpoint-up(md) {
            font-size: 16px;
            line-height: 26px;
        }
    }

    &.size--lg {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.03em;

        @include media-breakpoint-up(md) {
            font-size: 20px;
            line-height: 30px;
        }
    }
}

html {
    @include media-breakpoint-down(sm) {
        scrollbar-width: none;

        ::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none;
    }
}

.modal-splash-container {
    display: inline-flex;
    justify-content: center;

    @include media-breakpoint-up(sm) {
        justify-content: flex-start;
    }

    .splash-country-selector {
        .flag-icon {
            display: inline-block;
            vertical-align: middle;
            line-height: 100%;
            width: 16px;
            height: 16px;
            margin-right: 4px;
            margin-bottom: 3px;
            border-radius: 90%;
        }

        .flag-icon.flag-icon-un {
            filter: invert(1);
        }

        .flag-icon::before {
            content: none;
        }

        .current-country {
            font-family: $font-family-sans-serif;
            font-style: normal;
            font-size: 14px;
            cursor: pointer;
        }

        .modal {
            padding: 0 !important;
            overflow: auto !important;
            -ms-overflow-style: none;
            scrollbar-width: none;

            .modal-dialog {
                height: 100%;
                max-width: 1130px;
                margin: 0;

                @include media-breakpoint-up(md) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                }

                .modal-content {
                    height: 100%;
                    overflow: auto;
                    padding: 70px 15px 102px 15px;

                    @include media-breakpoint-up(md) {
                        height: auto;
                        padding: 80px 96px;
                    }

                    .modal-header {
                        position: relative;
                        padding: 0 10px;
                        border-bottom: 1px solid $whisper-grey;

                        @include media-breakpoint-up(md) {
                            padding: 0;
                            border-bottom: none;
                        }

                        .close {
                            position: absolute;
                            z-index: 1;
                            bottom: 100%;
                            left: 100%;
                            padding: 0;
                            margin: 0 0 15px -40px;
                            font-size: 21px;
                            color: black;
                            opacity: 1;

                            &:hover {
                                opacity: 0.5;
                            }

                            @include media-breakpoint-up(md) {
                                margin: 0 0 44px 60px;
                                font-size: 14px;
                            }
                        }

                        .header {
                            font-family: $font-family-sans-serif;
                            font-style: normal;
                            font-size: 20px;
                            line-height: 24px;
                            text-transform: uppercase;
                            margin-bottom: 26px;
                            padding-bottom: 0;

                            @include media-breakpoint-up(md) {
                                margin-bottom: 33px;
                            }
                        }

                        .paragraph {
                            font-family: $font-family-sans-serif;
                            font-style: normal;
                            font-size: 14px;
                            line-height: 17px;
                            margin-bottom: 0;
                            padding-bottom: 42px;

                            @include media-breakpoint-up(md) {
                                font-size: 12px;
                                line-height: 15px;
                                width: 63%;
                            }

                            @include media-breakpoint-up(md) {
                                font-size: 12px;
                                line-height: 15px;
                                width: 63%;
                            }
                        }
                    }

                    .modal-body {
                        padding: 0;

                        @include media-breakpoint-up(md) {
                            padding: 0;
                        }

                        .continent {
                            font-family: $font-family-sans-serif;
                            font-style: normal;
                            font-size: 13.5px;
                            line-height: 20px;
                            letter-spacing: 0.1em;
                            text-transform: uppercase;

                            @include media-breakpoint-up(md) {
                                border-bottom: 0.5px solid $almost-black;
                                padding-bottom: 5px;
                                margin-bottom: 14px;
                                max-width: 250px;
                            }
                        }

                        .continent-locale-list {
                            padding-right: 10px;
                            padding-left: 10px;
                            padding-top: 12px;
                            border-bottom: 1px solid $whisper-grey;

                            &.show {
                                padding-bottom: 22px;
                            }

                            @include media-breakpoint-up(md) {
                                display: block;
                                border-bottom: none;
                                padding-right: 0;
                                padding-left: 0;
                                padding-bottom: 40px;
                                padding-top: 0;
                            }

                            .same-country-locales {
                                break-inside: avoid;
                                padding-left: 17px;
                                padding-bottom: 10px;

                                @include media-breakpoint-up(md) {
                                    padding-bottom: 11px;
                                }
                            }

                            .underline-whole-row:hover {
                                text-decoration: underline;
                            }

                            .language {
                                font-family: $font-family-sans-serif !important;
                                font-style: normal;
                                font-weight: 300;
                                color: $almost-black !important;
                                text-transform: capitalize;
                                font-size: 14px;
                                line-height: 17px;
                                white-space: nowrap;

                                @include media-breakpoint-up(md) {
                                    font-size: 12px;
                                    line-height: 14.5px;
                                }

                                .language-code {
                                    text-transform: lowercase;
                                }
                            }

                            .country {
                                font-family: $font-family-sans-serif !important;
                                font-style: normal;
                                font-weight: 500;
                                color: $almost-black !important;
                                text-transform: capitalize;
                                font-size: 14px;
                                line-height: 17px;

                                @include media-breakpoint-up(md) {
                                    font-size: 12px;
                                    line-height: 14.5px;
                                }
                            }

                            .plain-text-country {
                                cursor: text;

                                .country {
                                    text-decoration: none;
                                    pointer-events: none;
                                }
                            }

                            .shopping-bag-icon {
                                margin-left: -17px;
                                margin-right: 3px;
                                margin-bottom: 3px;
                                height: 12px;
                                width: 9px;
                                display: inline;
                            }
                        }

                        .accordion {
                            outline: none;

                            .europe-cols {
                                @include media-breakpoint-up(md) {
                                    -webkit-columns: 2;
                                    -moz-columns: 2;
                                    column-count: 2;
                                }
                            }

                            #europe {
                                @include media-breakpoint-up(md) {
                                    padding-bottom: 32px;
                                }
                            }

                            .accordion-trigger {
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;
                                z-index: 1;
                                cursor: pointer;
                            }

                            button.title {
                                height: 64px;
                                padding: 0 20.5px 0 10px;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                &.collapsed {
                                    border-bottom: 1px solid $whisper-grey;
                                }

                                p.title {
                                    margin: 0;
                                    padding: 0;
                                }

                                &:not(.collapsed) {
                                    .fa-plus {
                                        transform: rotate(45deg);
                                        transform-origin: center;
                                    }
                                }

                                .fa-plus {
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@import "../scss/experience/variables.scss";

body {
    font-weight: 300;
}

.container {
    @include media-breakpoint-up(lg) {
        padding-right: 40px;
        padding-left: 40px;
    }
}

.carousel {
    .icon-prev,
    .icon-next {
        background-color: $white;
        font-size: 1.875em;
        // width and height here need to use rem units because the font size used here is 30px
        height: 3rem;
        padding-top: 0.24em;
        width: 3rem;

        &::before {
            color: black;
            font-family: 'FontAwesome';
        }
    }

    .icon-prev {
        &::before {
            content: '\f104';
        }
    }

    .icon-next {
        &::before {
            content: '\f105';
        }
    }

    .carousel-control-prev {
        justify-content: flex-start;
    }

    .carousel-control-next {
        justify-content: flex-end;
    }
}

.nav-tabs {
    border-bottom: $border-width solid $grey3;
    align-items: flex-end;

    .nav-item {
        flex: 1;

        .nav-link {
            color: #929292;
            @extend .headline-5;

            &.active {
                border-bottom: 0.188em solid $black;
                margin-bottom: -1px;
                color: $black;
            }
        }
    }
}

.card {
    margin-bottom: 1.25rem;
    border: 0;
}

.card-header h4 {
    margin-bottom: 0;
}

.modal .modal-body {
    flex: 0 0 auto;
}

dt {
    color: $gray-700;
    font-weight: normal;
}

.custom-checkbox .custom-control-label::before {
    border: 1px solid $mid-grey;
    background-color: $white;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label {
    &::before {
        background-color: $black;
        border-color: $black;
    }

    &::after {
        background-image: $svg-check;
    }
}

.custom-control-input:disabled~.custom-control-label::before {
    background-color: $light-grey;
    border-color: $light-grey;
}

.custom-radio .custom-control-label::before {
    border: 1px solid $mid-grey;
    background-color: $white;
}

.custom-radio .custom-control-input:checked~.custom-control-label {
    &::before {
        background-color: $white;
        border-color: $black;
    }

    &::after {
        background-image: $svg-radio;
    }
}

.custom-checkbox .custom-control-label,
.custom-radio .custom-control-label {
    font-weight: 600;
    cursor: pointer;
}

.custom-control-input:disabled~.custom-control-label {
    font-weight: 300;
    cursor: auto;
}

.form-control {
    color: $black;
}

.form-control.is-invalid {
    background-image: none;
}

select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: $svg-select;
    background-repeat: no-repeat;
    background-position-x: calc(100% - 10px);
    background-position-y: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: .5625rem 2.1875rem .5625rem .75rem;
}

select.form-control.is-invalid {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: $svg-select;
    background-repeat: no-repeat;
    background-position-x: calc(100% - 10px);
    background-position-y: 50%;
}

.btn,
button,
.nav-link {
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

body.kb-only {
    .btn,
    button,
    .nav-link {
        &:focus {
            outline: auto;
            box-shadow: 0 0 0 0.2rem rgba(38, 135, 231, 0.5);
        }
    }
}

.navbar-expand-lg .navbar-nav .nav-link {
    @include media-breakpoint-up(md) {
        padding-right: 0.60rem;
        padding-left: 0.60rem;
    }
    @include media-breakpoint-up(xl) {
        padding-right: 0.60rem;
        padding-left: 0.60rem;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ispin-wrapper {
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 0;
  vertical-align: middle;
  width: 100%;

  &>input {
    text-align: center;
    border: none;
    padding: 0 30px;
    width: 100%;
    border: 1px solid silver;
  }

  .ispin-button {
    background-color: transparent;
    position: absolute;
    cursor: pointer;
    height: 100%;
    border: none;
    width: 40px;
    padding: 0;
    margin: 0;
    right: 0;
    top: 0;

    &:disabled {
      cursor: default;
      opacity: 0.5;
    }

    &-inc {
      right: 0;

      &::before {
        content: '+';
        font-size: 30px;
        font-weight: 100;
      }
    }

    &-dec {
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        content: '-';
        font-size: 30px;
        font-weight: 100;
      }
    }
  }
}
@import "ribbon";

.content-slot-container {
    .slider-grey .carousel-container {
        max-width: 1440px;
        margin: auto;
        padding: 0 0 1rem;
        
        .slick-track {
            display: flex;

            .slick-slide {
                float: none;
                height: auto;

                & > div {
                    height: 100%;

                    .product {
                        height: 100%;
                        
                        .product-tile {
                            height: 100%;
                            padding-bottom: 50px;
                            background-color: $lightest-grey;
                            border-radius: 12px;
                            overflow: hidden;

                            .heading {
                                position: absolute;
                                width: 100%;
                                top: 60px;
                                text-align: center;
                                z-index: 1;

                                @include media-breakpoint-up(md) {
                                    top: 45px;
                                }
                            }

                            .image-container {
                                padding-top: 30px;
                                padding-bottom: 0;

                                a > div {
                                    max-width: none;
                                }
                            }

                            .tile-body {
                                padding-right: 10px;
                                padding-left: 10px;

                                @include media-breakpoint-up(md) {
                                    padding-right: 24px;
                                    padding-left: 24px;
                                }

                                .has-paragraph {
                                    @include media-breakpoint-down(xs) {
                                        font-size: 13px;
                                    }
                                }

                                .brand-title {
                                    margin-bottom: 0;
                                    padding-top: 20px;
                                    font-weight: 400;
                                }

                                .pdp-link {
                                    a {
                                        margin-bottom: 0;
                                    }

                                    .designer span {
                                        font-weight: 400;
                                    }
                                }
                            }

                            &.tile-bottom {
                                padding-bottom: 0;

                                .image-container {
                                    padding-bottom: 50px;
                                    
                                    @include media-breakpoint-up(md) {
                                        padding-top: 0;
                                        padding-bottom: 0;
                                    }
                                }
                                .tile-body {
                                    position: absolute;
                                    width: 100%;
                                    bottom: 30px;
                                    left: 50%;
                                    transform: translateX(-50%);

                                    @include media-breakpoint-up(md) {
                                        bottom: 25px;
                                    }

                                    @include media-breakpoint-up(lg) {
                                        bottom: 45px;
                                    }
                                }
                            }

                        }
                    }
                }
            }
        }

        .slide-arrow {
            top: calc(50% - 21px);
            
            i {
                font-size: 2.75rem;
                color: $grey5;
                padding: 5px;
                background-color: $light-grey;
                border-radius: 50%;

                &:before {
                    display: block;
                }
            }
            
            &:hover {
                opacity: 0.6;
            }

            &.prev-arrow {
                left: 15px;
                i:before {
                    transform: translate(-2px, -2px);
                }
            }

            &.next-arrow {
                right: 45px;
                i:before {
                    transform: translate(2px, -2px);
                }
            }
        }

        .slick-dots {
            bottom: -30px;

            li {
                height: 15px;

                &:not(.slick-active) button {
                    &:focus::before {
                        opacity: .25;
                    }
                }

                button {
                    width: 15px;
                    height: 15px;

                    &::before {
                        width: 15px;
                        height: 15px;
                        color: $grey5;
                        font-size: 2rem;
                    }
                }
            }
        }
    }

    .carousel-container ~ .has-button {
        margin-top: -2.25rem;
        margin-bottom: 2.25rem;
    }
}
.container .breadcrumb {
    font-family: 'Inter';
    font-weight: 500;
    border-radius: 0;
    border: none;
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 18px;
    letter-spacing: 0.05em;
    padding: 0;
    display: flex;
    height: 52px;
    font-size: 10px;
    align-items: center;

    .breadcrumb-link {
        color: $grey5;
    }
}
.modal-background {
    background-color: $black;
    display: none;
    height: 100%;
    position: fixed;
    opacity: 0.5;
    width: 100%;
    top: 0;
    left: 0;
}

input[placeholder] {
    text-overflow: ellipsis;
}

header~#maincontent .container a:not(.btn-primary, .btn-secondary, .btn-tertiary, .btn-outline-primary, .nav-link, .breadcrumb-link, .line-item-name-link, .privacy-link) {
    color: $blue;
    font-weight: 500;

    .fa {
        font-weight: bold;
    }
}

.hide-order-discount {
    display: none;
}

.hide-shipping-discount {
    display: none;
}

.order-discount {
    color: $success;
}

.shipping-discount {
    color: $success;
}

.error-messaging {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
}

.error-hero {
    background-image: url('../images/storelocator.jpg');
    margin-bottom: 0.625em;
}

.error-message {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
}

.error.continue-shopping {
    margin-bottom: 6.25em;
}

.error-unassigned-category {
    color: $red;
}

.skip {
    position: absolute;
    left: -999px;
    top: 0;
    overflow: hidden;
    padding: 1em 1.5em;
    background: $white;
}

a.skip:active,
a.skip:focus,
a.skip:hover {
    left: 0;
    top: 0;
    width: auto;
    height: auto;
    z-index: 10000000;
    background: $white;
}

.card-header-custom {
    font-size: 1.5rem;
    margin-bottom: 0;
}

// myAccount
.my-account-bg {
    background-color: $grey2;
}

.text-align-center-sm-only {

    @include media-breakpoint-down(xs) {
        text-align: center;
    }
}

.text-align-center {
    text-align: center;
}
@import "../experience/variables.scss";
@import "../thirdParty/parsley.scss";

.form-group {
    margin-bottom: 0;

    &.required .form-control-label::before {
        content: "*";
        color: $danger;
    }

    .form-label {
        font-family:'Inter';
        font-weight: 500;
        margin-top: 1.875rem;
    }
}

.checkbox-form-container {
    margin: 30px 0;
}

.login-checkbox-form-container {
    display: flex;
    flex-flow: row wrap;
    align-content: space-between;
    justify-content: space-between;

    .custom-checkbox {
        flex-grow: 1;
    }
}

.form-legend {
    display: flex;
    flex-direction: row-reverse;
    font-weight: 500;
    font-size: 0.75rem;
    margin-top: 0.625rem;
}
.mandatory {
    text-align: right;
    width: 100%;
    display: block;
    margin: 25px 0;
}

.prefix-selector {
    .input-wrapper {
        display: flex;
        .current-flag {
            position: absolute;
            align-self: center;
            left: 25px;
            z-index: 3;
        }
        input.form-control.dropdown-toggle {
            color: $black;
            display: block;
            width: 100%;
            height: calc(1.5em + 1.125rem + 2px);
            padding: .5625rem .75rem .5625rem 2.9375rem;
            margin-right: 0;
            font-size: 1rem;
            font-weight: 300;
            font-family: 'Inter';
            line-height: 1.5;
            text-align: left;
            background-color: $white;
            -webkit-background-clip: padding-box;
            background-clip: padding-box;
            border: 1px solid $gray-400;
            border-radius: 2px;
            transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
            cursor: pointer;
        }
    }

    .dropdown-menu.dropdown-prefix-selector {
        margin: 1px;
        left: 9px;
        width: calc(100% - 20px);
        max-height: 570px;

        background-color: $white;
        color: $black;
        border: 1px solid $gray-400;
    
        overflow-y: scroll;
        overflow-x: hidden;

        z-index: 4;

        @include media-breakpoint-down(md) {
            &::-webkit-scrollbar {
                display: none;
            }
        }

        @include media-breakpoint-down(xs) {
            left: 4px;
            width: calc(100% - 10px);
        }

        &::before,
        &::after {
            content: none;
        }

        .dropdown-item {
            padding: 10px 18px;

            span {
                color: black;
                font-weight: normal;
                font-family: 'Inter';
                padding: 0;
                font-size: 14px;
            }
        }

        .dropdown-item {
            text-transform: capitalize;
        }
    }
}

/*
 * Bootstrap's native variables for grid generation
*/

$grid-breakpoints: (
    xs: 0,
    sm: 544px,
    md: 769px,
    lg: 1023px,
    xl: 1200px
) !default;

$container-max-widths: (
    lg: 1200px,
    xl: 1440px
) !default;

@import "formFields";

.form-container {

    &--padded {
        background-color: $white;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 15px;
        margin-right: 15px;
        width: calc(100% - 30px);
        padding: 15px;

        @include media-breakpoint-up(sm) {
            margin-top: 30px;
            margin-bottom: 60px;
            margin-left: 40px;
            margin-right: 40px;
            width: calc(100% - 100px);
            padding: 60px 50px;
        }

        @include media-breakpoint-up(xl) {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            padding: 60px 100px;
        }
    }

    h1 {
        font-size: 26px;
        line-height: 32px;
        text-transform: capitalize;
        color: $almost-black;
        margin-bottom: 28px;
        font-weight: 600;

        @include media-breakpoint-up(sm) {
            font-size: 36px;
            line-height: 44px;
        }
    }

    form {
        .form-control-label {
            font-family: 'Inter';
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $black;
            margin-top: 20px;
            margin-bottom: 10px;
        }

        .form-group.required .form-control-label::before {
            content: none;
        }

        .form-control[type="checkbox"] {
            width: auto;
            height: auto;
        }

        textarea {
            height: 120px;

            @include media-breakpoint-up(xl) {
                height: 86px;
            }
        }

        .dropdown-menu.dropdown-prefix-selector {
            top: calc(100% - 18px);
        }

        button#sendBtn {
            display: block;
            margin: 0 auto;
            padding-left: 70px;
            padding-right: 70px;

            @include media-breakpoint-up(sm) {
                margin-left: auto;
                margin-right: 0;
            }
        }
    }

    .form-privacy {
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 22px;

        @include media-breakpoint-up(sm) {
            margin-bottom: 40px;
        }

        .privacy-link {
            text-decoration: underline;
        }
    }

    .form-reCaptcha {
        @include media-breakpoint-down(sm) {
            margin-bottom: 40px;
        }

        @include media-breakpoint-up(sm) {
            float: right;
        }
    }

    .form-text {
        margin-bottom: 30px;
        line-height: 26px;
    }

    .language-box {
        min-height: 80px;
        background: rgba(176, 192, 208, 0.3);
        border: 1px solid $bluegrey;
        box-sizing: border-box;
        border-radius: 2px;
        margin-top: 10px;
        margin-bottom: 20px;
        padding: 18px 20px;
        display: flex;
        align-items: center;

        .language-box-text {
            margin-left: 20px;
            font-family: 'Inter';
            font-weight: 500;
        }

        .language-box-img {
            width: 90px;

            img {
                width: 100%;
            }
        }
    }

    .checkbox-label {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        margin-right: 16px;
        vertical-align: middle;
        -webkit-tap-highlight-color: transparent;
    }
    
    #checkbox-errors {
        margin-top: -9px;
    }
    
    .checkbox-wrapper {
        padding: 9px;
        flex: 0 0 auto;
        overflow: visible;
        font-size: 1.5rem;
        text-align: center;
        transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border-radius: 50%;
        color: inherit;
        border: 0;
        cursor: pointer;
        margin: 0;
        display: inline-flex;
        outline: 0;
        position: relative;
        align-items: center;
        user-select: none;
        vertical-align: middle;
        -moz-appearance: none;
        justify-content: center;
        text-decoration: none;
        background-color: transparent;
        -webkit-appearance: none;
        -webkit-tap-highlight-color: transparent;
    }
    
    .checkbox-wrapper:hover {
        background-color: rgba(97, 147, 255, 0.04);
    }
    
    .checkbox-inner {
        width: 100%;
        display: flex;
        align-items: inherit;
        justify-content: inherit;
        &.parsley-error {
            border: none !important;
        }
    }
    
    .checkbox-svg {
        fill: currentColor;
        width: 1em;
        height: 1em;
        display: inline-block;
        font-size: 1.5rem;
        flex-shrink: 0;
        user-select: none;
    }
    
    .checkbox-input {
        top: 0;
        left: 0;
        width: 100%;
        cursor: inherit;
        height: 100%;
        margin: 0;
        opacity: 0;
        padding: 0;
        z-index: 1;
        position: absolute;
    }
    
    .checkbox-input:checked ~ .checkbox-svg {
        fill: #6193ff;
    }
    
    .checkbox-input-communication a,
    .checkbox-input-privacy a {
        color: #0070d2;
        text-decoration: none;
        font-weight: 400;
    }
    
    .checkbox-input-communication a:hover,
    .checkbox-input-privacy a:hover {
        text-decoration: underline;
    }

    .custom-checkbox .custom-control-label,
    .custom-radio .custom-control-label {
        font-weight: inherit;
    }
}
@import "bootstrap/scss/mixins";

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:              Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-sans-serif-medium:       Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:               SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:                    $font-family-sans-serif !default;
$font-family-medium:                  $font-family-sans-serif-medium !default;


.image-headline-1 {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    font-size: 40px;
    line-height: 42px;
  }
}

.image-headline-2 {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    font-size: 32px;
    line-height: 34px;
  }
}

.image-headline-3 {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    font-size: 18px;
    line-height: 24px;
  }
}

.headline-1 {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;

  @include media-breakpoint-up(md) {
    font-size: 36px;
    line-height: 44px;
  }
}

.headline-2 {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;

  @include media-breakpoint-up(md) {
    font-size: 28px;
    line-height: 34px;
  }
}

.headline-3 {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  @include media-breakpoint-up(md) {
    font-size: 24px;
    line-height: 29px;
  }
}

.headline-4 {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;

  @include media-breakpoint-up(md) {
    font-size: 24px;
    line-height: 29px;
  }
}

.headline-5 {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;

  @include media-breakpoint-up(md) {
    font-size: 20px;
    line-height: 24px;
  }
}

.headline-6 {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  @include media-breakpoint-up(md) {
    font-size: 16px;
    line-height: 20px;
  }
}

.subhead-large {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    font-size: 14px;
    line-height: 17px;
  }
}

.subhead-small {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    font-size: 11px;
    line-height: 13px;
  }
}

.product-label {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;

  @include media-breakpoint-up(md) {
    font-size: 16px;
    line-height: 20px;
  }
}

.filter-title {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.text-link {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #0071C1;

  @include media-breakpoint-up(md) {
    font-size: 16px;
    line-height: 20px;
  }
}

.article-1 {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.03em;
  color: #313131;

  @include media-breakpoint-up(md) {
    font-size: 20px;
    line-height: 30px;
  }
}

.article-2 {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #313131;

  @include media-breakpoint-up(md) {
    font-size: 16px;
    line-height: 26px;
  }
}

.article-3 {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #313131;
}

.article-4 {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #313131;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-bold {
  font-weight: 500 !important;
}
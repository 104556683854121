body {
    #CybotCookiebotDialog {
        font-family: inherit;
        border-radius: 0;
        @media (max-width: 1279px) {
            max-height: 100%;
            width: 100%;
            height: 100%;
        }
    }
    #CybotCookiebotDialog * {
        font-family: inherit;
    }
    #CybotCookiebotDialogHeader {
        padding: 0.7em;
        @media (min-width: 1280px) {
            display: none;
        }
    }
    #CybotCookiebotDialogHeaderLogosWrapper {
        justify-content: center;
    }
    #CybotCookiebotDialogPoweredbyLink {
        width: auto;
    }
    #CybotCookiebotDialogPoweredbyImage {
        max-height: 2.5em;
    }
    #CybotCookiebotDialogPoweredbyCybot {
        display: none;
    }
    #CybotCookiebotDialogTabContent {
        @media (min-width: 1280px) {
            margin-left: 0 !important;
        }
    }
    #CybotCookiebotDialogBody .CybotCookiebotScrollContainer {
        @media (max-width: 1279px) {
            padding-top: 0;
        }
    }
    #CybotCookiebotDialogBodyContentTitle {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.1em;
        font-weight: 600;
        text-transform: uppercase;
        margin: 0 0 15px;
    }
    #CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogContentWrapper {
        max-width: 1400px;
    }
    #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
        border-radius: 5px;
    }
}

.cookie-accept-confirmation,
.cookie-reject-confirmation {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #171717;
    color: #fff;
    .content-wrapper {
        max-width: 960px + 30px;
        padding: 30px 15px;
        margin: 0 auto;
    }
    h2 {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.1em;
        font-weight: 600;
        text-transform: uppercase;
        margin: 0 0 20px;
    }
    p {
        font-size: 14px;
        line-height: 22px;
        margin: 0;
    }
}

@import "../variables";

.ribbon-wrapper {
    position: absolute;
    width: 100%;
    height: 45px;
    overflow: hidden;
    z-index: 1;

    @include media-breakpoint-up(lg) {
        height: 130px;
    }
    
    .ribbon {
        position: relative;
        top: 15px;
        left: 0;
        width: calc(100% - 16px);
        padding: 5px;
        background-color: $blue;
        color: $white;
        text-align: center;
        transform-origin: bottom right;

        @include media-breakpoint-up(lg) {
            position: absolute;
            max-width: 275px;
            width: 100%;
            top: 0;
            padding: 5px 85px 5px 30px;
            transform: translate(-15%, -120%) rotate(-30deg);
        }
    }

    &.ribbon-black {
        position: relative;
        width: calc(100% + 40px);
        height: auto;
        overflow: auto;
        margin: 0 -20px;

        .ribbon {
            position: relative;
            max-width: 100%;
            width: 100%;
            top: 0;
            padding: 5px;
            color: $gold;
            background-color: $black;
            transform: none;
        }
    }
}
.btn {
    padding: $btn-padding-y $btn-padding-x;
    font-weight: 500;
    font-family: 'Inter', serif;
    padding-top: 10px;
    border-radius: 5px;
}

.btn-primary,
.btn-secondary {

    &.disabled,
    &:disabled {
        color: $white;
        background-color: $mid-grey;
        border-color: $mid-grey;
    }
}

.btn-secondary {
    background-color: $black;
    border-color: transparent;

    &:not(:disabled):not(.disabled).active,
    &:hover {
        color: $black;
        background-color: transparent;
        border-color: transparent;
    }
}

.btn-tertiary {
    border: 2px solid $black;

    &:not(:disabled):not(.disabled).active,
    &:hover {
        color: $white;
        background-color: $black;
    }

    &.disabled,
    &:disabled {
        color: $mid-grey;
        background-color: transparent;
        border-color: $mid-grey;
    }
}

.btn-text-link {
    font-size: 14px;
    line-height: 18px;
    color: #0071C1;
    font-family: 'Inter', serif;

    &:hover,
    &.active {
        color: #0054A8 !important;
        text-decoration: none;
    }
}

.btn-product-label {
    font-size: 16px;
    line-height: 20px;
    color: $black !important;

    &:hover,
    &.active {
        color: $black !important;
        text-decoration-line: underline;
    }
}

.btn-close {
    height: 30px;
    width: 30px;
    padding: 0;
    border: 0;
    background-color: transparent;
    color: inherit;
    appearance: none;
    font-size: 14px;
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 1;
    @include media-breakpoint-up(sm) {
        top: 20px;
        right: 20px;
    }
}
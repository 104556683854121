@import "../utilities/swatch";
@import "productCommon";
@import "quickView";

.primary-images {
    .carousel {
        img {
            width: 100%;
            height: 100%;
        }
    }
}

.count-results-wrapper {
    min-height: calc(100vh - 295px);
    @include media-breakpoint-up(sm) {
        min-height: calc(100vh - 283px);
    }
}

.product-tile {

    @include media-breakpoint-down(md) {
        min-height: 23.4375em;
    }

    @include media-breakpoint-down(sm) {
        min-height: 19.6875em;
    }

    @include media-breakpoint-down(xs) {
        min-height: 13.4375em;
    }

    border: 0;
    margin-bottom: 0;
    position: relative;

    .store-exclusive {
        position: absolute;
        top: 12px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 185px;
        text-align: right;
        z-index: 1;
        @include media-breakpoint-up(sm) {
            top: 20px;
            max-width: 225px;
        }
        @include media-breakpoint-up(md) {
            max-width: 215px;
        }
        @include media-breakpoint-up(xl) {
            max-width: 285px;
        }

        > span {
            width: 100%;
            max-width: 127px;
            background: rgba(176, 192, 208, 0.3);
            padding: 6px 9px;
            color: $hastens-dark-blue;
            font-size: 9px;
            line-height: 1;
            letter-spacing: 0.1em;
            text-transform: uppercase;
        }

        // max-width: 185px;
        // @include media-breakpoint-up(sm) {
        //     max-width: 225px;
        // }
        // @include media-breakpoint-up(md) {
        //     max-width: 215px;
        // }
        // @include media-breakpoint-up(xl) {
        //     max-width: 285px;
        // }
    }

    .tile-body {
        padding-top: 0;
        text-align: center;

        .color-swatches {
            margin-bottom: 15px;
            min-height: 0;
            line-height: 1;

            @include media-breakpoint-up(sm) {
                margin-bottom: 20px;
            }

            .product-tile-color-label {
                cursor: pointer;
                font-size: 1em;

                @include media-breakpoint-down(md) {
                    font-size: 0.9375em;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 0.8125em;
                }
            }
        }

        .price {
            font-size: 1.125em;
            line-height: 20px;
            color: $dark-grey;

            @include media-breakpoint-down(md) {
                font-size: 1.0625em;
            }

            @include media-breakpoint-down(sm) {
                font-size: 1em;
            }

            .tiered {
                font-size: 0.875em;

                .value {
                    font-weight: bold;
                }
            }

            .from {
                color: $dark-grey;
                font-size: 10px;
                line-height: 12px;
                @include media-breakpoint-up(sm) {
                    font-size: 12px;
                    line-height: 15px;
                }
                ~ span {
                    color: $black;
                    font-size: 14px;
                    line-height: 17px;
                    @include media-breakpoint-up(sm) {
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
            }
            .sales {
                > .value {
                    color: $black;
                }
            }
        }

        .coming-soon-tile {
            text-align: center;
        }

        .brand-title {
            font-size: 12px;
            line-height: 22px;
        }

        .pdp-link {
            margin-bottom: 10px;
            line-height: 1;
            @include media-breakpoint-up(sm) {
                margin-bottom: 14px;
            }

            a {
                display: block;
                font-size: 14px !important;
                line-height: 17px !important;
                text-decoration: none;
                color: $black !important;
                margin-bottom: 8px;

                @include media-breakpoint-down(md) {
                    font-size: 0.9375em;
                }

                @include media-breakpoint-up(sm) {
                    font-weight: 500;
                    font-size: 17px !important;
                    line-height: 22px !important;
                }

                span {
                    display: inline-block;
                    position: relative;
                    @include media-breakpoint-up(lg) {
                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            bottom: -3px;
                            left: 50%;
                            height: 0.1rem;
                            width: 0;
                            background: transparent;
                            transition: width .25s ease, background-color .25s ease, left .25s ease;
                        }
                        &.on-it {
                            &:after {
                                width: 100%;
                                left: 0;
                                background: $black;
                            }
                        }
                    }
                }  
            }
            .designer {
                span {
                    font-size: 12px;
                    line-height: 22px;
                }
            }
        }

        .ratings {
            font-size: 0.9em;
        }
    }

    .image-container {
        position: relative;
        overflow: auto;
        padding: 46px 0 30px;
        @include media-breakpoint-up(sm) {
            padding: 58px 0 43px;
        }
        @include media-breakpoint-up(lg) {
            padding: 68px 0 50px;
        }

        .quickview {
            position: absolute;
            bottom: 1rem;
            right: 1rem;

            i {
                &.fa-expand {
                    color: rgba(0, 0, 0, 0.7);
                }

                &.fa-circle {
                    color: rgba(255, 255, 255, 0.7);
                    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
                }
            }
        }

        a {
            display: block;
            position: relative;

            > div {
                display: block;
                margin: 0 auto;
                max-width: 185px;
                overflow: hidden;

                @include media-breakpoint-up(sm) {
                    max-width: 225px;
                }
                @include media-breakpoint-up(md) {
                    max-width: 215px;
                }
                @include media-breakpoint-up(xl) {
                    max-width: 285px;
                }
            }

            .tile-image {
                width: 100%;
                transform: scale(1);
                transition: transform .3s ease;
                // @include media-breakpoint-up(xl) {
                //     &.on-it {
                //         transform: scale(1.08);
                //     }
                // }
            }
        }
    }

    .swatches {
        height:20px; // placeholder
        a {
            text-decoration: none;
        }
        display: flex;
        justify-content: center;
        .more-colors {
            display: flex;
            align-items: flex-end;
        }
        a, span {
            &.subhead-small {
                padding-left: 5px;
                text-transform: lowercase;
                color: $dark-grey !important;
                letter-spacing: inherit;
                pointer-events: none;
                font-size: 10px;
                line-height: 12px;
                @include media-breakpoint-up(sm) {
                    padding-left: 10px;
                    font-size: 11px;
                    line-height: 13px;
                }
            }
        }
        > a {
            &:last-of-type {
                .swatch-circle {
                    margin-right: 0;
                }
            }
        }
    }

    .swatch-circle {
        // $white will be replaced by color image background via Javascript
        @include swatch(1.8em, $white);
        width: 16px;
        height: 16px;
        margin-right: 3px;
        border: 1px solid #e5e5e5;
        @include media-breakpoint-up(sm) {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
    }
}

#chooseBonusProductModal {
    .modal-footer {
        .container {
            margin-left: 0;
            width: 100%;
            margin-right: 0;
        }
    }

    .select-cbp-container {
        margin-top: auto;
        margin-bottom: auto;
    }

    .product-name-wrapper {
        width: 100%;
    }

    .bonus-quantity,
    .bonus-option {
        margin-top: 0.938em;
    }

    .bonus-quantity-select {
        min-width: 5em;
    }

    .select-bonus-product {
        margin-top: 1em;
    }

    .selected-pid {
        border: 1px solid $grey3;

        .bonus-product-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .bonus-product-price {
        text-align: center;
        margin-top: 1em;
    }
}
